import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import BeeIcon from '../../images/ReeBee';
import profilePicture from '../../images/profile.png';

const ChatPrompt = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasShown, setHasShown] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300 && !hasShown) {
        setIsOpen(true);
        setHasShown(true);
        window.removeEventListener('scroll', handleScroll);
      }
    };

    if (!hasShown) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasShown]);

  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className="fixed bottom-4 right-4 z-50">
      <div className="relative">
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, x: 20, y: 10, scale: 0.3 }}
              animate={{ opacity: 1, x: 0, y: 0, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
              className="absolute bottom-full right-0 mb-2 bg-white rounded-lg shadow-lg p-6 w-72"
            >
              <button
                onClick={() => setIsOpen(false)}
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              >
                ×
              </button>
              <div className="flex items-center mb-4">
                <img 
                  src={profilePicture} 
                  alt="Grey" 
                  className="w-12 h-12 rounded-full mr-3 object-cover"
                />
                <h3 className="text-lg font-semibold">Connect with Grey</h3>
              </div>

             
              <div className="mt-4 text-sm text-gray-500">
              Have questions? Want to learn more?  Email me at{' '}
                <a href="mailto:grey@epigenesis.us" className="text-purple-600 hover:underline">
                  grey@epigenesis.us
                </a>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <motion.button
          className="bg-purple-600 bg-opacity-80 text-white p-3 rounded-full shadow-lg hover:bg-purple-700 transition duration-300 flex items-center justify-center"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={toggleOpen}
        >
          <BeeIcon className="w-6 h-6" />
        </motion.button>
      </div>
    </div>
  );
};

export default ChatPrompt;