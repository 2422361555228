import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from './hooks/useAuthContext';
import BeeIcon from '../images/ReeBee';
import HeroSection from './landing/HeroSection';
import AboutSection from './landing/AboutSection';
import CorrelationExplanation from './landing/CorrelationExplination';
import ChatPrompt from './landing/ChatPrompt';

const Landing = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();

  if (user) {
    navigate('/home');
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
    <header className="bg-white shadow-sm">

    </header>

      <main className="flex-grow">
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <section className="px-4 py-8 sm:px-0">
            <HeroSection />
          </section>

          <AboutSection />
          {/* <CorrelationExplanation /> */}

          <section id="faqs" className="py-16">
            <div className="container mx-auto px-4">
              <h2 className="text-3xl font-bold text-slate-900 mb-8">Frequently Asked Questions</h2>
              <div className="space-y-8">
                {[  
                  // {
                  //   question: 'How accurate are the correlations?',
                  //   answer: 'Our correlation calculations provide insights based on your data. While they can be very informative, remember that correlation does not mean causation and this is only as accurate as the data you provide. Always consult with a healthcare professional for medical advice.',
                  // },
                  {
                    question: 'Is my health data secure?',
                    answer: 'We use industry-standard encryption and security measures to protect your data. Your privacy is our top priority.',
                  },
                  {
                    question: 'Can I use Reebee for multiple conditions?',
                    answer: 'Yes, Reebee is designed to track symptoms for various conditions including allergies, asthma, COPD, and eczema.',
                  },
                  // {
                  //   question: 'How does the pattern recognition work?',
                  //   answer: 'Our algorithm analyzes your logged data over time to identify potential correlations between your symptoms and various factors like diet, environment, and activities.',
                  // },
                  // {
                  //   question: 'Can I share my data with my doctor?',
                  //   answer: 'Soon! We launch custom report to share with your healthcare provider during consultations.',
                  // },
                ].map((faq, index) => (
                  <div key={index}>
                    <h3 className="text-xl font-semibold mb-2">{faq.question}</h3>
                    <p className="text-gray-600">{faq.answer}</p>
                  </div>
                ))}
              </div>
            </div>
          </section>

          {/* Updated Call to Action section */}
          <section className="bg-gradient-to-r from-purple-100 to-blue-100 py-20 relative overflow-hidden">
            <div className="container mx-auto px-4 text-center relative z-10">
              <h2 className="text-3xl font-bold mb-4 text-gray-800">Take Control of Your Health Journey</h2>
              <p className="mb-8 text-xl text-gray-700">Start tracking your symptoms and unlock insights that can transform your life.</p>
              <button 
                onClick={() => navigate('/signup')}
                className="bg-purple-600 text-white px-8 py-3 rounded-md text-lg font-semibold hover:bg-purple-700 transition duration-300 shadow-lg"
              >
                Get Started Now
              </button>
            </div>
            {/* You can add your SVG flower background here */}
            <div className="absolute inset-0 z-0 opacity-20">
              {/* Add your SVG flower pattern here */}
            </div>
          </section>
        </div>
      </main>

      <footer className="bg-white">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="space-y-8 xl:col-span-1">
              <div className="flex items-center">
                <BeeIcon className="w-10 h-10 mr-3" />
                <span className="text-2xl font-bold text-gray-900">Reebee</span>
              </div>
              <p className="text-gray-500 text-base">
                Empowering you to take control of your health journey.
              </p>
            </div>
            <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Product</h3>
                  <ul role="list" className="mt-4 space-y-4">
                    <li>
                      <a href="#faqs" className="text-base text-gray-500 hover:text-gray-900">
                        FAQs
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Support</h3>
                  <ul role="list" className="mt-4 space-y-4">
                    <li>
                    <a href="/contact" className="text-base text-gray-500 hover:text-gray-900">
  Contact Us
</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Company</h3>
                  <ul role="list" className="mt-4 space-y-4">
                    <li>
               
<a href="/about" className="text-base text-gray-500 hover:text-gray-900">
  About
</a>
                    </li>
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">Legal</h3>
                  <ul role="list" className="mt-4 space-y-4">
                    <li>
                    <a href="/privacy-policy" className="text-base text-gray-500 hover:text-gray-900">
  Privacy Policy
</a>
                    </li>
                    <li>
                      <a href="/terms-of-service" className="text-base text-gray-500 hover:text-gray-900">
                        Terms of Service
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 border-t border-gray-200 pt-8">
            <p className="text-base text-gray-400 xl:text-center">
              &copy; {new Date().getFullYear()} Epigenesis LLC. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
      <ChatPrompt />
    </div>
  );
};

export default Landing;


