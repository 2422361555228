import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useAuthContext } from '../hooks/useAuthContext';
import { db } from '../../firebase/config';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import useFetchUserQuests from '../hooks/useFetchUserQuests';
import useGeolocation from '../hooks/useGeolocation';
import useFetchWeatherData from '../hooks/useFetchWeatherData';
import useFetchAQIData from '../hooks/useFetchAQIData';
import useFetchPollenData from '../hooks/useFetchPollenData';

import FormHeader from './FormHeader';
import ItemEntry from './ItemEntry';
import SymptomEntry from './SymptomEntry';
import entryData from '../../entry-inputs-two.json';
import EnvironmentalDataDisplay from './EnvironmentalDataDisplay';
import QuestCompletionModal from './QuestCompletionModal';

function FormComponent({ initialDate, onClose }) {
    const [currentDate, setCurrentDate] = useState(() => initialDate || new Date());
    const { user } = useAuthContext();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [submittedEntries, setSubmittedEntries] = useState([]);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitError, setSubmitError] = useState(null);

    useEffect(() => {
        if (initialDate) {
            setCurrentDate(initialDate);
        }
    }, [initialDate]);

    const { quests, loading: questsLoading, error: questsError } = useFetchUserQuests(user ? user.uid : null);
    const { location, error: locationError, isFetching: locationLoading } = useGeolocation();
    const { weatherData, loading: weatherLoading, error: weatherError } = useFetchWeatherData(location?.latitude, location?.longitude);
    const { aqiData, loading: aqiLoading, error: aqiError } = useFetchAQIData(location?.latitude, location?.longitude);
    const { pollenData, loading: pollenLoading, error: pollenError } = useFetchPollenData(location?.latitude, location?.longitude);

    const defaultFormValues = {
        symptoms: {},
        foods: [],
        activities: [],
        medications: [],
        moods: [],
    };

    const methods = useForm({
        defaultValues: defaultFormValues
    });

    const { handleSubmit, formState, getValues, setValue, watch } = methods;

    const watchedFoods = watch('foods');
    const watchedActivities = watch('activities');
    const watchedMedications = watch('medications');
    const watchedMoods = watch('moods');

    const handleFormChange = (data) => {
        if (data && typeof data === 'object') {
            const formIsDirty = Object.keys(data).some(key => {
                const fieldValue = data[key];
                if (Array.isArray(fieldValue)) {
                    return fieldValue.length > 0;
                } else if (typeof fieldValue === 'object' && fieldValue !== null) {
                    return Object.keys(fieldValue).length > 0;
                }
                return !!fieldValue;
            });
            setIsFormDirty(formIsDirty);
        } else {
            setIsFormDirty(false);
        }
    };

    const onSubmit = async (data) => {
        setIsSubmitting(true);
        setSubmitError(null);
        try {
            if (!user) {
                throw new Error('No user logged in');
            }

            const entriesCollectionRef = collection(db, `users/${user.uid}/entries`);
            const newEntries = [];

            const currentDateNoon = new Date(currentDate);
            currentDateNoon.setHours(12, 0, 0, 0);
            const customTimestamp = Timestamp.fromDate(currentDateNoon);

            const now = new Date();
            const isToday = currentDate.toDateString() === now.toDateString();
            const entryTimestamp = isToday ? Timestamp.now() : customTimestamp;

            // Process symptom entries
            if (data.symptoms && Object.keys(data.symptoms).length > 0) {
                for (const [system, symptoms] of Object.entries(data.symptoms)) {
                    if (symptoms.length > 0) {
                        const newEntry = {
                            type: 'symptom',
                            system: system,
                            values: symptoms.map(s => ({ value: s.value, amount: s.amount })),
                            setAt: entryTimestamp,
                            createdAt: Timestamp.now(),
                            updatedAt: Timestamp.now()
                        };
                        const docRef = await addDoc(entriesCollectionRef, newEntry);
                        newEntries.push({ id: docRef.id, ...newEntry });
                    }
                }
            }

            // Process other types of entries
            const types = ['foods', 'activities', 'medications', 'moods'];
            for (const type of types) {
                if (data[type] && data[type].length > 0) {
                    const newEntry = {
                        type: type,
                        values: data[type].map(item => ({ value: item.value, amount: item.amount })),
                        setAt: entryTimestamp,
                        createdAt: Timestamp.now(),
                        updatedAt: Timestamp.now()
                    };
                    const docRef = await addDoc(entriesCollectionRef, newEntry);
                    newEntries.push({ id: docRef.id, ...newEntry });
                }
            }

            // Add environmental data entry
            if (location || weatherData || aqiData || pollenData) {
                const newEntry = {
                    type: 'environmental',
                    location: location,
                    weather: weatherData,
                    aqi: aqiData,
                    pollen: pollenData,
                    setAt: entryTimestamp,
                    createdAt: Timestamp.now(),
                    updatedAt: Timestamp.now()
                };
                const docRef = await addDoc(entriesCollectionRef, newEntry);
                newEntries.push({ id: docRef.id, ...newEntry });
            }

            setSubmittedEntries(newEntries);
            setIsFormDirty(false);
            
            // Check if there are any completed quests
            const completedQuests = quests.filter(quest => {
                // Implement your quest completion logic here
                // For example, check if the submitted entries satisfy any quest conditions
                return false; // Placeholder
            });

            if (completedQuests.length > 0) {
                // If there are completed quests, show the modal
                setIsModalOpen(true);
            } else {
                // If no quests are completed, close the form
                handleModalClose();
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setSubmitError('Failed to save entry. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleDateChange = (newDate) => {
        setCurrentDate(newDate);
    };

    const handleClose = () => {
        if (isFormDirty) {
            if (window.confirm('You have unsaved changes. Are you sure you want to close the form?')) {
                if (typeof onClose === 'function') {
                    onClose();
                }
            }
        } else {
            if (typeof onClose === 'function') {
                onClose();
            }
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        if (typeof onClose === 'function') {
            onClose();
        }
        // Redirect to the Explore page
        navigate('/explore');
    };

    return (
        <div className="fixed inset-0 bg-white z-50 overflow-hidden flex flex-col">
            <FormHeader 
                selectedDate={currentDate}
                onClose={handleClose}
                onDateChange={handleDateChange}
            />
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)} className="flex-grow overflow-y-auto">
                    <div className="max-w-3xl mx-auto p-4 space-y-6">
                        <SymptomEntry setIsFormDirty={setIsFormDirty} />
                        <ItemEntry 
                            type="foods" 
                            items={entryData.foods} 
                            amountName="amount.foods" 
                            setIsFormDirty={setIsFormDirty} 
                            selectedValues={watchedFoods}
                        />
                        <ItemEntry 
                            type="activities" 
                            items={entryData.activities} 
                            amountName="amount.activities" 
                            setIsFormDirty={setIsFormDirty} 
                            selectedValues={watchedActivities}
                        />
                        <ItemEntry 
                            type="medications" 
                            items={entryData.medications} 
                            amountName="amount.medications" 
                            setIsFormDirty={setIsFormDirty} 
                            selectedValues={watchedMedications}
                        />
                        <ItemEntry 
                            type="moods" 
                            items={entryData.moods}
                            amountName="amount.moods" 
                            setIsFormDirty={setIsFormDirty} 
                            selectedValues={watchedMoods}
                        />
                        <EnvironmentalDataDisplay 
                            location={location}
                            weatherData={weatherData}
                            aqiData={aqiData}
                            pollenData={pollenData}
                            locationLoading={locationLoading}
                            weatherLoading={weatherLoading}
                            aqiLoading={aqiLoading}
                            pollenLoading={pollenLoading}
                            locationError={locationError}
                            weatherError={weatherError}
                            aqiError={aqiError}
                            pollenError={pollenError}
                        />
                    </div>
                </form>
            </FormProvider>
            {isFormDirty && (
                <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 transition-all duration-300 ease-in-out">
                    <div className="w-full max-w-screen-md mx-auto p-4">
                        <button 
                            type="button"
                            onClick={handleSubmit(onSubmit)}
                            disabled={isSubmitting}
                            className={`w-full py-3 px-4 rounded-md shadow-lg ${
                                isSubmitting 
                                    ? 'bg-purple-400 cursor-not-allowed' 
                                    : 'bg-purple-600 hover:bg-purple-700'
                            } text-white transition-colors duration-200`}
                        >
                            {isSubmitting ? 'Saving...' : 'Save All'}
                        </button>
                        {submitError && (
                            <p className="mt-2 text-red-600 text-sm">{submitError}</p>
                        )}
                    </div>
                </div>
            )}
           <QuestCompletionModal
                isOpen={isModalOpen}
                onClose={handleModalClose}
                quests={quests}
                entries={submittedEntries}
            />
        </div>
    );
}

export default FormComponent;